.red {
    color: red !important;
}

img{
    max-width: 75%;
}

footer {
    display: block;
    font-size: 14px;
    font-family: Verdana, sans-serif;
    background-color: #f5f5f5;
    padding: 30px 0;
}
footer .footer-widget h3 {
    margin-bottom: 30px;
}
footer .contact-link {
    display: inline-block;
    width: 100%;
    color: #333;
}
footer .footer-social ul {
    padding-left: 0;
}
footer .footer-social ul li {
    list-style: none;
    float: left;
    padding: 0 10px;
}
footer .footer-social ul li:first-child {
    padding-left: 0;
}
footer .footer-social ul li a {
    font-size: 20px;
    color: #333;
}
footer .footer-social ul li a:hover {
    color: red;
}

footer .footer-social2 ul li a:hover {
    color: blue;
}

footer .opening-time {
    display: block;
    padding-left: 0;
}
footer .opening-time li {
    list-style: none;
}
footer .opening-time li span {
    float: left;
    padding-right: 10px;
}
footer .opening-time li span .fa-times {
    color: red;
}
footer .opening-time li strong {
    color: red;
}
footer .opening-time2 li strong {
    color: green;
}
footer .media-body a {
    color: #333;
}
footer .media-body a:hover {
    color: blue;
}
footer .media-body span {
    color: #969696;
}
footer .images-gellary ul {
    padding-left: 0;
}
footer .images-gellary ul li {
    list-style: none;
    float: left;
    margin: 0 3% 2% 0;
    width: 31%;
    position: relative;
}
footer .images-gellary ul li:nth-child(3n) {
    margin: 0 0 1%;
}